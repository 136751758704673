@import "./theme.scss";

/** Header **/
.analysis-header {
    background-color: $niwa-dark-blue;
    padding: 0px 20px;
    border-bottom: 6px solid $niwa-light-blue;
    min-height: 96px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2, h4 {
        color: white;
        margin: 0px;
    }

    h2 {
        font-size: 30px;
    }

    h4 {
        font-size: 20px;
    }
}


/** Tabs **/

.nav {

    .nav-item {

        .nav-link {
            border-radius: 0px;
        }

        .disabled-tab {
            color: lightblue;
        }

        .disabled-tab:hover {
            color: lightblue;
            cursor: default;
        }

        .nav-link.active {
            border: 0px;
            background-color: $niwa-dark-blue;
            color: white
        }
    }

    .nav-item:hover {
        cursor: pointer;
    }
}
