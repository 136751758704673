@import "./theme.scss";

.species-biomass-grid {
margin: 30px;
margin-left: 50px;

.biomass-grid-graph {
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    margin-bottom: 10px;
    margin-right: 15px
}
}

.biomass-button-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 40px;
  margin-bottom: 0px;
  align-items: flex-end;
  height: 100%;

  p {
    margin: 0px;
  }
}
