@import "./theme.scss";

.species-details {
  display: flex;
  height: 100px;

  .fixed-width {
    max-width: 150px;
    min-width: 150px;
  }

  .detail-block {
    margin: 15px;
    margin-top: 0px;
    padding: 0px 10px;

    p {
      margin-bottom: 0px;
      font-size: 16px;
    }

    strong {
      font-weight: 300;
      color: grey;
      font-size: 15px;
    }
  }
}
