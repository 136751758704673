@import "./theme.scss";

.section-info-area {
    overflow-y: auto;
}

.section-info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;

    h4 {
        margin-bottom: 0px;
    }
}
