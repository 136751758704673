@import "./theme.scss";

svg {
  font: 10px sans-serif;
}

.d3-example {
  height: 100%;
  padding: 20px;
}

.background path {
  fill: none;
  stroke: none;
  stroke-width: 20px;
  pointer-events: stroke;
}

.selected-species-path {
  stroke: black !important;
  stroke-width: 2px !important;
}

.secondary-species-path {
  stroke: grey !important;
  stroke-width: 2px !important;
  stroke-dasharray: 10,10;
}

.foreground path {
  fill: none;
  stroke: steelblue;
  stroke-width: 1.5px;
  position: relative;
  z-index: 2;
}

.areaforeground path {
  fill: lightblue;
  opacity: 0.3;
  position: relative;
  z-index: 1;
}

.axis .title {
  font-size: 8px;
  font-weight: bold;
  text-transform: uppercase;
}

.axis line,
.axis path {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.label {
  -webkit-transition: fill 125ms linear;
}

.selected-species-label {
  font-weight: bold !important;
}

.secondary-species-label {
  font-weight: bold !important;
  fill: #424242ff !important;
}


.active .label:not(.inactive) {
  font-weight: bold;
}

.label.inactive {
  fill: black;
}

.foreground path.inactive {
  stroke: #ccc;
  stroke-opacity: 0.5;
  stroke-width: 1px;
}

.d3-chart .areaforeground path.inactive {
  stroke-opacity: 0;
  fill: none;
}

.axis .tick:hover {
  cursor: pointer;
}

.dimension .clickable-title {
  cursor: pointer;
}

.biomass-tooltip {
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 3px;
  z-index: 100;
}
