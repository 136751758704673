@import "./theme.scss";
@import "./species-list.scss";
@import "./analysis-panel.scss";
@import "./custom-chart.scss";
@import "./species-details.scss";
@import "./section-information.scss";
@import "./biomass-charts.scss";

.app-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.map-container {
  overflow: hidden;
}

#biomass-chart {
  width: 100%;
  margin-top: 30px;
}

.bar-chart {
  width: 100%;

  .svg-container,
  .main-svg {
    width: 100% !important;
  }
}

h1 {
  font-size: 20px !important;
}

.leaflet-container {
  width: 100%;
  margin: 0 auto;
}

.no-data-available {
  padding: 15px;
  font-size: 25px;
}

.no-margin {
  margin: 0px !important;
}

.no-padding {
  padding: 0px !important;
}


