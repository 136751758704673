@import "./theme.scss";

/** Search bar **/

.species-search {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    height: 70px;


    input {
        width: 90%;
        border: 0px;
        padding: 10px;
        font-size: 20px;
    }

    i {
        font-size: 20px;
        padding: 10px;
    }
}


/** SPECIES LIST **/

.species-list {

    border-radius: 0px !important;

    .species-list-item {
        border-radius: 0px !important;
        

        .com-name, .sci-name {
            margin: 0px;
            padding-left: 20px;
        }

        .com-name {
            font-weight: bold;
            font-size: 20px;
        }

        .sci-name {
            font-size: 15px;
        }
    }

    .species-list-item:hover {
        background-color: $subtle-hover-color;
        cursor: pointer;
    }

    .selected, .selected:hover {
        background-color: $niwa-dark-blue;
        color: white;
    }
}